exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-danke-js": () => import("./../../../src/pages/kontakt-danke.js" /* webpackChunkName: "component---src-pages-kontakt-danke-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-templates-topic-page-1-js": () => import("./../../../src/templates/topic-page-1.js" /* webpackChunkName: "component---src-templates-topic-page-1-js" */),
  "component---src-templates-topic-page-2-js": () => import("./../../../src/templates/topic-page-2.js" /* webpackChunkName: "component---src-templates-topic-page-2-js" */),
  "component---src-templates-topic-page-3-js": () => import("./../../../src/templates/topic-page-3.js" /* webpackChunkName: "component---src-templates-topic-page-3-js" */)
}


module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Toolbox Innenstadt","short_name":"Toolbox","start_url":"/","background_color":"#fff","display":"minimal-ui","icon":"src/images/app-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"78defc3cb30a8ca08769b707cb1ab26f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-redux-persist/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/state/createStore"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import { createStore } from "redux"
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';


const persistConfig = {
    key: 'root',
    storage
};

const reducer = (state, action) => {
  if (action.type === `SET_TOPIC_STARTED`) {
    return Object.assign({}, state, {
        topics: Object.assign({}, state.topics, {
            [action.payload.topic]: Object.assign({}, state.topics[action.payload.topic], {
                started: true
            })
        })
    })
  } else if (action.type === `SET_TOPIC_FINISHED`) {
    return Object.assign({}, state, {
        topics: Object.assign({}, state.topics, {
            [action.payload.topic]: Object.assign({}, state.topics[action.payload.topic], {
                finished: true
            })
        })
    })
  } else if (action.type === `SET_TOPIC_ANSWER`) {
    return Object.assign({}, state, {
        topics: Object.assign({}, state.topics, {
            [action.payload.topic]: Object.assign({}, state.topics[action.payload.topic], {
                answers: Object.assign({}, state.topics[action.payload.topic].answers, {
                    [action.payload.questionId]: action.payload.answer
                })
            })
        })
    })
  }  else if (action.type === `UNSET_TOPIC_ANSWER`) {
    return Object.assign({}, state, {
        topics: Object.assign({}, state.topics, {
            [action.payload.topic]: Object.assign({}, state.topics[action.payload.topic], {
                answers: Object.assign({}, state.topics[action.payload.topic].answers, {
                    [action.payload.questionId]: undefined
                })
            })
        })
    })
  } else if (action.type === `SET_TOPIC_PROGRESS`) {
    return Object.assign({}, state, {
        topics: Object.assign({}, state.topics, {
            [action.payload.topic]: Object.assign({}, state.topics[action.payload.topic], {
                completionRate: action.payload.completionRate
            })
        })
    })
  } else if (action.type === `DISMISS_MODAL`) {
    return Object.assign({}, state, {
        dismissModal: true,
      })
  }  else if (action.type === `SHOW_MODAL`) {
    return Object.assign({}, state, {
        dismissModal: false,
      })
  }
  return state
}

const initialState = {
    dismissModal: false, 
    topics: {
        topic1: {
            finished: false,
            started: false,
            completionRate: 0,
            answers: {}
        },
        topic2: {
            finished: false,
            started: false,
            completionRate: 0,
            answers: {}
        },
        topic3: {
            finished: false,
            started: false,
            completionRate: 0,
            answers: {}
        },
        topic4: {
            finished: false,
            started: false,
            completionRate: 0,
            answers: {}
        },
        topic5: {
            finished: false,
            started: false,
            completionRate: 0,
            answers: {}
        },
        topic6: {
            finished: false,
            started: false,
            completionRate: 0,
            answers: {}
        },
        topic7: {
            finished: false,
            started: false,
            completionRate: 0,
            answers: {}
        },
        topic8: {
            finished: false,
            started: false,
            completionRate: 0,
            answers: {}
        },
        topic9: {
            finished: false,
            started: false,
            completionRate: 0,
            answers: {}
        },
        topic10: {
            finished: false,
            started: false,
            completionRate: 0,
            answers: {}
        },
        topic11: {
            finished: false,
            started: false,
            completionRate: 0,
            answers: {}
        },
        topic12: {
            finished: false,
            started: false,
            completionRate: 0,
            answers: {}
        },
        topic13: {
            finished: false,
            started: false,
            completionRate: 0,
            answers: {}
        }
    }
}

const persistedReducer = persistReducer(persistConfig, reducer)

//const createStore = () => reduxCreateStore(reducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
//const createStore = () => reduxCreateStore(reducer, initialState)
//export default createStore

export default () => {
    let store = createStore(persistedReducer, initialState)
    let persistor = persistStore(store)
    return { store, persistor }
}